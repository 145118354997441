import { makeStyles } from '@material-ui/core/styles'

export const useErrorStyle = makeStyles({
  helperText: {
    fontSize: '11px',
    marginTop: '8px',
    minHeight: '1em',
    textAlign: 'left',
    fontFamily:
      'Noto Sans SC,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
    fontWeight: 400,
    lineHeight: '1em',
    letterSpacing: '0.33px',
    color: '#e53935',
    margin: '8px 14px 0',
    whiteSpace: 'pre-wrap',
  },
})

export { default as Page } from './Page'
export { default as TextField } from './TextField'
export { default as Checkbox } from './Checkbox'
export { default as Toggle } from './Toggle'
export { default as AccountEdit } from './AccountEdit'
export { default as SubmitButton } from './SubmitButton'
export { default as Label } from './Label'
export { default as SubTitle } from './SubTitle'
export { default as Select } from './Select'
export { default as Paginate } from './Paginate'
export { default as Footer } from './Footer'
export { default as Loading } from './Loading'
export { default as GroupOptions } from './GroupOptions'
export { default as DatePicker } from './DatePicker'
export { default as ProgressStatus } from './ProgressStatus'
export { default as TopBar } from './TopBar'
export { default as Alert } from './Alert'
export { default as BulkChange } from './BulkChange'
export { default as Button } from './Button'
export { default as ButtonGroup } from './ButtonGroup'
export { default as ConfirmModal } from './ConfirmModal'
export { default as DataTable } from './DataTable'
export { default as FieldGroup } from './FieldGroup'
export { default as Filter } from './Filter'
export { default as FormField } from './FormField'
export { default as OperationPanel } from './OperationPanel'
export { default as Radio } from './Radio'
export { default as ScrollToTop } from './ScrollToTop'
export { default as SearchButton } from './SearchButton'
export { default as SearchPanel } from './SearchPanel'
export { default as StyledTableCell } from './StyledTableCell'
export { default as Tab } from './Tab'
export { default as TabForMessage } from './TabForMessage'
export { default as TabMcb } from './TabMcb'
export { default as TabNormal } from './TabNormal'
export { default as TabForMessageMycareerbox } from './TabForMessageMycareerbox'
export { default as Table } from './Table'
export { default as Title } from './Title'
export { default as GroupHorizontal } from './GroupHorizontal'
export { default as InputWithCounter } from './InputWithCounter'
export { default as MultiTextWithCounter } from './MultiTextWithCounter'
export { default as TextareaAutosize } from './TextareaAutosize'
export { default as MapTextField } from './MapTextField'
export { default as MultilineEllipsie } from './MultilineEllipsie'
export { default as DataTimePicker } from './DataTimePicker'
export { default as DataTimePickerCustom } from './DataTimePickerCustom'
export { default as SelectForMessage } from './SelectForMessage'
export { default as SelectForMessageReact } from './SelectForMessageReact'
export { default as TextFieldForMessage } from './TextFieldForMessage'
export { default as SelectMessage } from './SelectMessage'
export { default as SelectMessageReact } from './SelectMessageReact'
export { default as InputWithPostalCode } from './InputWithPostalCode'
export { default as AddressModal } from './AddressModal'
export { default as ContentMessage } from './ContentMessage'
export { default as MajorField } from './MajorField'
export { default as SchoolDetailedDivision } from './SchoolDetailedDivision'
export { default as FiftyPhonogram } from './FiftyPhonogram'
export { default as SchoolNameSelect } from './SchoolNameSelect'
export { default as TypeSuggestSelect } from './TypeSuggestSelect'
export { default as TypeToggle } from './TypeToggle'
export { default as InputWithContent } from './InputWithContent'
export { default as PromptBack } from './PromptBack'
export { default as CheckBoxCoulumn } from './CheckBoxCoulumn'
export { default as LoadingDialog } from './LoadingDialog'
export { default as SelectOfTargetYear } from './SelectOfTargetYear'
export { default as SelectionProcessResultDialog } from './SelectionProcessResultDialog'
