import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React, { useEffect } from 'react'
import { PropsInterface } from './interface'
import { magiContants } from 'utils/contants'
import { getMessage } from 'common/messageUtil'
import './style_group_SelectionProcessResult.css'

const useStyles = makeStyles({
  checkboxtext: {
    margin: '8px',
  },
  checkedtext: {
    color: 'rgba(0, 0, 0, 0.38)',
  },
})

export default function SelectionProcessResultDialog(props: PropsInterface) {
  const classes = useStyles()
  // ダイアログメッセージの定義
  let dialogMessage = new Map([
    ['0', null],
    ['1', magiContants.MESSAGECODE_MCAYS030_022], //判定中
    ['2', magiContants.MESSAGECODE_MCAXS020_012], //参加
    ['3', magiContants.MESSAGECODE_MCAXS020_010], //合格
    ['4', magiContants.MESSAGECODE_MCAXS020_013], //不参加
    ['5', magiContants.MESSAGECODE_MCAXS020_011], //不合格
    ['6', magiContants.MESSAGECODE_MCAYS030_022], //その他
  ])

  // ダイアログメッセージの定義
  let checkItemList = new Map([
    ['0', null],
    ['1', null], //判定中
    ['2', [magiContants.MESSAGECODE_MCAXS020_014]], //参加
    ['3', [magiContants.MESSAGECODE_MCAXS020_014]], //合格
    ['4', null], //不参加
    [
      '5',
      [
        magiContants.MESSAGECODE_MCAXS020_016,
        magiContants.MESSAGECODE_MCAXS020_017,
      ],
    ], //不合格
    ['6', null], //その他
  ])

  // 条件により変更するメッセージの定義
  let conditionalMessagesList = new Map([
    ['3', [magiContants.MESSAGECODE_MCAXS020_015]], //合格(内定)
  ])

  const [dialogTitle, setDialogTitle] = React.useState(
    dialogMessage.get(props.selectionProcessResult)
  )
  const [checkItem, setCheckItem] = React.useState(
    checkItemList.get(props.selectionProcessResult)
  )
  const [SelectCheckbox, setSelectCheckbox] = React.useState('0')

  // キャンセルボタンクリックイベント
  const cancelClick = () => {
    setSelectCheckbox('0')
    // 呼び出し元のメソッドに返却
    props.cancelClick()
  }

  // OKボタンクリックイベント
  const okClick = () => {
    // 呼び出し元のメソッドに返却
    props.okClick(SelectCheckbox)
    setSelectCheckbox('0')
  }

  // チェックイベント
  const checkboxChange = (id: string) => (e: any) => {
    if (id === SelectCheckbox) {
      setSelectCheckbox('0')
    } else {
      setSelectCheckbox(id)
    }
  }

  useEffect(() => {
    if (
      props.selectionProcessResult === '2' ||
      props.selectionProcessResult === '3'
    ) {
      setSelectCheckbox('1')
    } else {
      setSelectCheckbox('0')
    }

    setDialogTitle(dialogMessage.get(props.selectionProcessResult))
    if (props.selectionProcessResult === '3' && props.nextJobOfferFlg) {
      setCheckItem(conditionalMessagesList.get(props.selectionProcessResult))
    } else {
      setCheckItem(checkItemList.get(props.selectionProcessResult))
    }
  }, [props])

  if (dialogTitle == null || undefined) {
    return null
  } else {
    return (
      <Dialog open={props.openSelectionProcessResultDialog}>
        <DialogTitle>{getMessage(dialogTitle)}</DialogTitle>
        {checkItem == null || checkItem == undefined ? (
          ''
        ) : props.checkboxFlg ? (
          <DialogContent>
            {checkItem.map((name, index) => (
              <div>
                {SelectCheckbox === '0' ? (
                  <div onClick={checkboxChange((index + 1).toString())}>
                    <input
                      type='checkbox'
                      id={(index + 1).toString()}
                      checked={false}
                    />
                    <label
                      className={classes.checkboxtext}
                      dangerouslySetInnerHTML={{
                        __html: getMessage(name),
                      }}
                    />
                  </div>
                ) : SelectCheckbox === (index + 1).toString() ? (
                  <div onClick={checkboxChange((index + 1).toString())}>
                    <input
                      type='checkbox'
                      id={(index + 1).toString()}
                      checked={true}
                    />
                    <label
                      className={classes.checkboxtext}
                      dangerouslySetInnerHTML={{
                        __html: getMessage(name),
                      }}
                    />
                  </div>
                ) : (
                  <div className={classes.checkedtext}>
                    <input
                      type='checkbox'
                      id={(index + 1).toString()}
                      disabled
                    />
                    <label
                      className={classes.checkboxtext}
                      dangerouslySetInnerHTML={{
                        __html: getMessage(name),
                      }}
                    />
                  </div>
                )}
              </div>
            ))}
          </DialogContent>
        ) : (
          ''
        )}
        <div>
          <DialogActions>
            <Button onClick={() => cancelClick()}>キャンセル</Button>
            <Button onClick={() => okClick()} value='cancel' color='primary'>
              OK
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    )
  }
}
